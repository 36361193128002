.caption-panel {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;

    &__links {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        @screen sm {
            display: inline;
            margin-top: 0;
        }
    }

    &__text-container {
        flex-grow: 1;

        @screen md {
            padding-left: 30px;

        }
    }
}

.caption-panel .caption-panel__image {
    border-radius: 0.375rem;
    margin-top: 3px;
    height: 75px;
    width: 110px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    flex-grow: 0;
    flex-shrink: 0;
}

@screen sm {
    .caption-panel .caption-panel__image {
        width: 135px;
        height: 110px;
    }
}

@screen md {
    .caption-panel .caption-panel__image {
        height: 145px;
        width: 210px;
    }
}
