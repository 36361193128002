@import "../abstract/var";

$container-max-width: 1250px;
$container-padding-x: 15px;

/**
 * .ch24-content needs top padding because the navbar and the job search bar have a fixed position on certain
 * breakpoints.
 * Up to the `fullmenu` breakpoint we have the mobile menu height, after that we have the desktop menu height.
 */
.ch24-content {
  flex: 1;

  & > *:last-child {
    padding-bottom: 40px !important;
    margin-bottom: 0 !important;
  }
}

@screen lg {
  .ch24-content {
    padding-top: calc($menu-mobile-height + $find-job-bar-desktop-height);

    &--search {
      padding-top: 3rem;
    }
  }
}

@screen fullmenu {
  .ch24-content {
    padding-top: calc($menu-desktop-height + $find-job-bar-desktop-height);
  }
}

.ch24-block {
  @apply my-10;
}

.ch24-container {
  margin: 0 auto;
  max-width: $container-max-width;
  padding-left: $container-padding-x;
  padding-right: $container-padding-x;

  &--full {
    max-width: 100% !important;
  }

  &--from-medium {
    padding-left: 0;
    padding-right: 0;

    @screen sm {
      margin: 0 auto;
      max-width: $container-max-width;
      padding-left: $container-padding-x;
      padding-right: $container-padding-x;
    }
  }
}
