.ch24-badge-container {
  @apply -m-1 inline-flex flex-wrap;

  &--filterable {
    .ch24-badged {
      cursor: pointer;
    }
  }

  .ch24-badged {
    @apply inline-block py-1 px-3 m-1 transition-[border-color];
    border: 1px solid var(--gray-400);
    border-radius: 20px;
    color: var(--gray-600);
    font-size: 12px;
    transition-duration: 150ms;
    transition-property: color, border-color;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    @screen md {
      font-size: 14px;
      padding: 0.2rem 0.5rem;
    }

    &:hover {
      border-color: #333333;
    }

    &--active {
      border: 1px solid var(--blue-500);
      color: var(--blue-500);
    }
  }
}
