.ch24-horizontal-carousel__next,
.ch24-horizontal-carousel__previous {
  @apply flex items-center transform;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 50%;
  z-index: 20;
  height: 100%;

  @screen sm {
    top: 50%;
  }
}

.ch24-horizontal-carousel__next.is-visible,
.ch24-horizontal-carousel__previous.is-visible {
  @apply -translate-y-1/2;
  opacity: 1;
  pointer-events: auto;
}

.ch24-horizontal-carousel__previous {
  left: 10px;

  @screen sm {
    @apply -translate-x-1/2;
    left: 0;
  }
}

.ch24-horizontal-carousel__next {
  right: 10px;

  @screen sm {
    @apply translate-x-1/2;
    right: 0;
  }
}

.ch24-horizontal-carousel__slide {
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;

  .ch24-tile {
    @apply shadow;
  }
}
