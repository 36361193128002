@layer utilities {
  .ch24-text-blue-800 {
    color: var(--blue-800);
  }

  .ch24-text-blue-600 {
    color: var(--blue-600);
  }

  .ch24-text-gray-800 {
    color: var(--gray-800);
  }

  .ch24-text-gray-600 {
    color: var(--gray-600);
  }

  .ch24-text-gray-500 {
    color: var(--gray-500);
  }

  .ch24-border-gray-600 {
    border-color: var(--gray-600);
  }

  .ch24-border-gray-300 {
    background-color: var(--gray-300);
  }

  .ch24-border-gray-200 {
    background-color: var(--gray-200);
  }

  .ch24-bg-blue-600 {
    background-color: var(--blue-600);
  }

  .ch24-bg-blue-700 {
    background-color: var(--blue-700);
  }
  
  .ch24-bg-blue-800 {
    background-color: var(--blue-800);
  }

  .ch24-bg-blue-200 {
    background-color: var(--blue-200);
  }

  .ch24-bg-gray-200 {
    background-color: var(--gray-200);
  }
}

.highlight-anchor-links {
  a {
    @extend .ch24-link
  }
}

.style-bullet-list {
  ul {
    @apply list-outside ml-[15px] #{!important}
  }
}
