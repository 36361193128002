@keyframes spinAround {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  to {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

.is-loading {
  position: relative;
  opacity: 70%;
  /*color: transparent !important;
  pointer-events: none;*/
}

.is-loading::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.is-loading::after {
  @apply transform -translate-x-1/2;
  content: "";
  display: block;
  height: 3.125rem;
  width: 3.125rem;
  position: absolute;
  left: calc(50% - calc(3.125rem / 2));
  top: calc(40vh - $menu-mobile-height);
  border-width: .625rem;
  border-style: solid;
  border-color: rgba(0, 94, 168, 1) rgba(0, 0, 0, .3) rgba(0, 0, 0, .3) rgba(0, 0, 0, .3);
  border-radius: 50%;
  animation: spinAround 700ms infinite linear;
}

@media (min-width: 48em) {
  .is-loading::after {
    height: 5rem;
    width: 5rem;
    left: calc(50% - calc(5rem / 2));
    top: calc(40vh - $menu-desktop-height);
  }
}

.loader-container>.is-loading {
  width: 2.5rem;
  height: 2.5rem;
  margin: 0 auto 1rem auto;
}

.loader-container>.is-loading:after {
  margin-top: -1.25rem;
  top: 50%;
}

@media (min-width: 48em) {
  .loader-container>.is-loading {
    width: 5rem;
    height: 5rem;
  }

  .loader-container>.is-loading::after {
    height: 5rem;
    width: 5rem;
    margin-left: -2.5rem;
    margin-top: -2.5rem;
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 1);
}

.loader.is-transparent {
  background: rgba(255, 255, 255, .75);
}

.loader-mask {
  display: table;
  margin: 6.25rem auto 0 auto;
}

.loader-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.loader-container {
  background: #ffffff;
  padding: 1.25rem;
}

.loader.is-transparent .loader-container {
  @apply drop-shadow;
  border: 1px solid #dcdcdc;
}

.loader-container>.loader-message {
  font-size: 1.125rem;
  text-align: center;
  margin: 0 auto;
  color: rgb(0, 94, 168);
}


button>.is-loading {
  display: inline;
  margin-left: 24px;
  position: absolute;
  top: 50%;
}

button>.is-loading::after {
  height: 24px;
  width: 24px;
  margin-left: -12px;
  margin-top: -12px;
  border-width: 4px;
  border-color: rgba(255, 255, 255, 1) rgba(255, 255, 255, .4) rgba(255, 255, 255, .4) rgba(255, 255, 255, .4);
  top: 50%;
}

@media (min-width: 48em) {
  button>.is-loading::after {
    height: 20px;
    width: 20px;
    margin-left: -10px;
    margin-top: -10px;
    border-width: 4px;
  }
}
