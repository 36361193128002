$menu-desktop-height: #{var(--menu-desktop-height)};
$find-job-bar-desktop-height: #{var(--find-job-bar-desktop-height)};

$menu-mobile-height: 3rem;

$search-page-side-bar-padding-left-desktop: 50px;
$search-page-side-bar-width-desktop: 500px;

/** The main menu needs to collapse before the md breakpoint, because we have to many items in it.
 * A screen has been added to the tailwind.config.js named `fullmenu`, which also represents this value.
 */
$menu-collapse-width: 1454px;
