@import "utilities";

:root {
    --blue-800: #063773;
    --blue-700: #015fa4;
    --blue-600: #005ea8;
    --blue-500: #0271c2;
    --blue-200: #ecf7fd;
    --gray-800: #333333;
    --gray-600: #666666;
    --gray-500: #999999;
    --gray-400: #b4b4b4;
    --gray-300: #dcdcdc;
    --gray-200: #f4f4f4;
    --yellow-400: #ffbb1c;
    --green-500: #008300;
    --menu-desktop-height: 65px;
    --find-job-bar-desktop-height: 56.85px;
    --content-padding-top: calc(var(--menu-desktop-height) + var(--find-job-bar-desktop-height));

    font-family: Arial, Helvetica, sans-serif;
    color: var(--gray-600);
}

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

input {
    -webkit-appearance: none;
}
