.ch24-arrow-button {
    -webkit-box-shadow: 0 12px 24px rgb(0 0 0 / 5%), 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px rgb(60 64 67 / 15%);
    box-shadow: 0 12px 24px rgb(0 0 0 / 5%), 0 1px 2px rgb(60 64 67 / 30%), 0 1px 3px rgb(60 64 67 / 15%);
    background-color: #fff;
    border-radius: 50%;
    color: #5f6368;
    height: 40px;
    width: 40px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;

    @screen sm {
        @apply transition-shadow;
        height: 60px;
        width: 60px;

        &:hover {
            -webkit-box-shadow: 0 12px 24px rgb(0 0 0 / 5%), 0 1px 6px rgb(60 64 67 / 30%), 0 1px 5px rgb(60 64 67 / 15%);
            box-shadow: 0 12px 24px rgb(0 0 0 / 5%), 0 1px 6px rgb(60 64 67 / 30%), 0 1px 5px rgb(60 64 67 / 15%);
        }
    }
}

.ch24-arrow-button:focus,
.ch24-arrow-button:hover {
    color: #202124
}

.ch24-arrow-button__icon {
    height: 9px;
    width: 15px;

    &--next {
        transform: rotate(-90deg);
    }

    &--previous {
        transform: rotate(90deg);
    }
}
