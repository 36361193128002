.list-bullet {
    float: left;
    margin-right: 20px;
    margin-top: 6px;
    background: var(--gray-300);
    height: 16px;
    width: 16px;
    line-height: 30px;
    border-radius: 100px;
    font-weight: 700;
    color: white;
    text-align: center;
}

.gray-line {
    background: var(--gray-300);
    z-index: -1;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 8px;
    top: 6px;
}

.white-line {
    background: #FFF;
    z-index: -1;
    top: 6px;
    width: 1px;
    height: 100%;
    position: absolute;
    left: 8px;
}
