// loading indicator
#items-loading {
    @apply absolute flex justify-center top-0 left-0 w-full h-full -z-10 opacity-0 transition-opacity duration-300 ease-in;
    background: rgba(240, 240, 240, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  
    &:after {
      @apply sticky block animate-spin border-[10px] border-solid border-r-brand border-gray-300 rounded-full;
      content: "";
      top: 50%;
      height: 72px;
      width: 72px;
    }
  
    &.htmx-request {
      @apply z-10 opacity-100;
    }
  }