.template-benefits-page {
  .ch24-benefit__icon {
    height: 40px;
    width: 40px;
  }

  .ch24-list {
    margin-left: 15px;
  }

  .ch24-benefits__headline {
    display: flex;
    font-size: 18px;
    font-weight: bold;
    justify-content: space-between;

    @screen sm {
      color: #333;
      font-weight: normal;
      font-size: 24px;
    }
  }

  .ch24-benefits__subheadline {
    font-size: 16px !important;

    @screen sm {
      font-size: 18px !important;
    }
  }

  .ch24-benefits__headline img {
    color: var(--gray-400);
  }

  @screen sm {
    .ch24-benefit__icon {
      height: 60px;
      width: 60px;
    }
  }

  .ch24-benefits-text {
    margin-top: 15px;

    @screen sm {
      margin-top: 30px;
    }
  }
}
