.ch24-form-button {
    @apply shadow;
    border-radius: 9999px;
    text-align: center;
    background-color: var(--blue-500) !important;
    padding: 10px 30px;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    font-size: 14px;
    min-width: 120px;

    &:hover {
        color: white;
        background-color: var(--blue-700) !important;
    }

    &:focus {
        color: white;
    }

    &--xl {
        font-size: 18px;
        height: 50px;
    }

    @screen sm {
        font-size: 18px;
        height: 50px;
        min-width: 205px;
    }

    &--medium-small {
        @media (max-width: theme('screens.sm.max-width')) {
            padding: 6px 20px;
            font-size: 14px;
        }
    }

    &--no-radius {
        border-radius: 0;
    }

    &:disabled {
        background-color: var(--gray-400) !important;
        cursor: not-allowed;
    }
}
