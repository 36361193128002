.ch24-banner {
  align-items: center;
  display: flex;
  justify-items: center;

  &-inner {
    @apply ch24-container;
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-items: center;
    padding: 20px 5px;
    gap: 20px;
  }

  &-text {
    color: #333333;
    font-size: 18px;
    text-align: center;
  }

  &-cta {
    @apply transition-[background-color];
    border-radius: 30px;
    font-size: 18px;
    padding: 10px 30px;
    text-align: center;
    width: 100%;

    @screen xs {
      width: auto;
    }

    &:hover {
      background-color: var(--blue-700) !important;
    }
  }

  @screen md {
    min-height: 100px;

    &-inner {
      flex-direction: row;
      padding: 0;
      gap: 30px;
    }

    .ch24-banner-cta {
      margin-top: 0;
    }

    &-text {
      margin-bottom: 0;
      text-align: left;
    }
  }

  &+.ch24-section,
  .ch24-section+& {
    border-top: 0 !important;
  }

  &+.ch24-divider,
  .ch24-divider+& {
    display: none;
  }

  // Styles
  &--blue {
    @apply ch24-bg-blue-200;

    .ch24-banner-cta {
      color: #ffffff;
      background-color: #0271c2;
    }
  }
}

.ch24-banner-paragraph, .ch24-banner-text {
  @screen md {
    &:not(&--desktop) {
      display: none;
    }
  }

  @media (max-width: theme('screens.xs.max-width')) {
    &:not(&--mobile) {
      display: none;
    }
  }
}
