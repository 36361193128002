.ch24-input {
    @apply transition-colors;
    cursor: text;
    outline: none;

    >input {
        &::placeholder {
            color: var(--gray-500);

            @screen sm {
                color: var(--gray-400);
            }
        }

        &:focus {
            outline: none;
        }
    }

    &:focus-within {
        outline: none;

        .ch24-input-icon.ch24-input-icon--colorize-on-focus {
            color: var(--blue-600);
        }
    }

    &-icon {
        &--map-marker {
            width: 10px;
            height: 14px;
            margin-right: 10px;

            @screen sm {
                height: 22px;
                width: 16px;
            }
        }
    }

    &:hover {
        border-color: #575757;
    }
}

.ch24-input.ch24-input__simple {
    @apply transition-shadow transition-colors;
    padding: 10px 25px;
    border: 1px solid #b4b4b4;
    border-radius: 50px;
    font-size: 16px;
    height: 45px;
    display: flex;

    &:focus-within {
        border-color: var(--blue-600) !important;
        -webkit-box-shadow: 0px 2px 5px -4px var(--blue-600);
        -moz-box-shadow: 0px 2px 5px -4px var(--blue-600);
        box-shadow: 0px 2px 5px -4px var(--blue-600);
    }

    &:hover:not(:focus) {
        border-color: var(--blue-600);
    }

    @screen sm {
        height: 50px;
    }

    .ch24-input-icon {
        color: #b4b4b4;
    }
}

.ch24-input.ch24-input__simple.ch24-input__simple--location-select {
    height: fit-content;
}

.ch24-input.ch24-input__simple::placeholder {
    color: var(--gray-400);

    @screen sm {
        color: var(--gray-500);
    }
}
