.ch24-layout-two-card {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    margin: 0 auto;
}

.ch24-layout-two-card__item {
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: 20px;
    position: relative
}

@screen sm {
    .ch24-layout-two-card__item {
        height: 200px
    }
}

@screen md {
    .ch24-layout-two-card__item {
        height: 400px;
        margin-bottom: 30px
    }
}
