@import '../layout/structure';

.ch24-divider {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin: 40px 0;
  height: 1px;
  background-color: var(--gray-300);

  &--my-20px {
    margin: 20px 0;
  }

  &--no-margin {
    margin: 0 0;
  }

  &--search-result {
    margin: 15px 0;
  }

  &--my-50px {
    margin: 50px 0;
  }

  &--mb-only {
    margin-top: 0;
  }

  &--only-desktop {
    display: none;

    @screen md {
      display: block;
    }
  }

  &--container-size {
    margin: 40px auto;
    max-width: calc(100vw - calc($container-padding-x * 2));

    @media (min-width: $container-max-width) {
      max-width: calc($container-max-width - calc($container-padding-x * 2));
    }
  }
}
