.ch24-rich-text {
  li:not(:first-child) {
    margin-top: 5px;
  }

  ul {
    list-style: inside;
    font-size: 16px;
    list-style-position: outside;
    margin-left: 20px;
  }

  p {
    line-height: 22px;

    a {
      -webkit-appearance: none;
      background: transparent;
      border: 0;
      cursor: pointer;
      display: inline-block;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      font-family: Arial, Helvetica, sans-serif;
      text-decoration: none;
      -webkit-transition: background .15s, color .15s;
      transition: background .15s, color .15s;
    }
  }

  p:not(:first-child) {
    margin-top: 10px;

    @screen sm {
      @apply mt-5;
    }
  }

  h2 {
    font-size: 24px;

    @screen md {
      font-size: 30px;
    }
  }

  h3 {
    font-size: 18px;

    @screen md {
      font-size: 20px;
    }
  }

  h4 {
    font-size: 18px;

    @screen md {
      font-size: 20px;
    }
  }
}
