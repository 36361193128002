// todo: modifier class exists, but not base class
.check24-has-sections--with-dividers {
  .ch24-section {
    border-top: 1px solid var(--gray-300);

    &:first-child {
      border-top: 0;
    }
  }
}
