.share-button {
    @apply relative;

    &::after {
        @apply hidden z-10 absolute rounded shadow top-0 left-1/2 transform translate-y-[calc(-100%-10px)] translate-x-[-50%] bg-[#dadada] text-[#333333] text-xs px-2 py-0.5 transition-opacity opacity-0 content-[attr(data-shared-copied)];

        @screen xs {
            @apply whitespace-nowrap;
        }
    }

    &.shared::after {
        @apply inline-block opacity-100;
    }
}
