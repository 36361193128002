.ch24-hero-panel {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: 0 auto;
    object-fit: cover;
    width: 100%;

    @screen sm {
        @apply rounded-md;
        height: 180px;
    }

    @screen md {
        height: 300px;
        margin: 1rem auto;
    }
}
