.ch24-link,
a {
    @apply transition-colors;
    -webkit-appearance: none;
    background: transparent;
    border: 0;
    color: var(--blue-600);
    cursor: pointer;
    display: inline-block;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 400;
    text-decoration: none;
}

.ch24-link:focus,
.ch24-link:hover,
.ch24-link:active,
a:focus,
a:hover,
a:active {
    color: #C05702;

    &.no-effect {
        color: inherit;
    }
}

.ch24-link--small {
    font-size: 12px
}

.ch24-link--on-grey {
    color: var(--blue-500)
}

.ch24-link>.ch24-icon,
a>.ch24-icon {
    margin-left: 4px;
    position: relative;
    top: 4px
}
