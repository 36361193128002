footer {
  padding-top: 20px;
  padding-bottom: 40px;

  @screen xs {
    padding-bottom: 20px;
  }

  @screen md {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.ch24-footer-divider {
  @apply px-3;
  margin: 20px 0;
  height: 1px;
  background-color: var(--gray-300);
}

.ch24-footer-gender-neutral-text {
  color: var(--gray-500);
}

.ch24-footer-social-media {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    h3 {
      flex-shrink: 0;
    }

    @screen sm {
      flex-direction: row;
      align-items: center;
    }
  }
}
