@import "../abstract/var";

/* override the .ch24-content top padding, because we don't have the job search bar on home. Adapt the padding on the
 * `fullmenu` breakpoint, which is when the main menu grows larger.
 */
.ch24-content-home {
  padding-top: $menu-mobile-height;

  @screen fullmenu {
    padding-top: $menu-desktop-height;
  }
}

.ch24-home-hero {
  padding-bottom: 40px;
  padding-top: 20px;

  @screen md {
    padding-top: 40px;
    padding-bottom: 80px;
  }
}

.ch24-home-hero .ch24-home-hero__title {
  font-size: 36px;
  line-height: 36px;
}

@media (max-width: theme('screens.sm.max-width')) {
  #search-form .ch24-headline {
    font-size: 18px;
  }
}

@screen md {
  .ch24-home-hero .ch24-home-hero__title {
    font-size: 70px;
    line-height: 70px;
  }
}

.ch24-home-hero .ch24-home-hero__content-wrapper {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 2;
  order: 2
}

@screen md {
  .ch24-home-hero .ch24-home-hero__content-wrapper {
    -webkit-box-ordinal-group: initial;
    -ms-flex-order: initial;
    order: 0
  }
}

.ch24-home-hero__image-wrapper {
  margin-bottom: 20px;
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1
}

@screen md {
  .ch24-home-hero__image-wrapper {
    margin-bottom: 0;
    -webkit-box-ordinal-group: initial;
    -ms-flex-order: initial;
    order: 0
  }
}

.ch24-home-spotlight {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(44%, transparent), color-stop(44%, var(--gray-200)));
  background: linear-gradient(180deg, transparent 44%, #e8eaed 0);
  overflow: hidden;
  padding-bottom: 40px;
  position: relative;

  @screen xs {
    padding-bottom: 70px;
  }

  @screen md {
    background: -webkit-gradient(linear, left top, left bottom, color-stop(37%, transparent), color-stop(37%, var(--gray-200)));
    background: linear-gradient(180deg, transparent 37%, var(--gray-200) 0);
  }
}

.ch24-home-spotlight .ch24-home-spotlight__slide {
  min-width: 90%;
  opacity: 0;
  padding-right: 20px;
  -webkit-transition: opacity .25s linear;
  transition: opacity .25s linear
}

@screen xs {
  .ch24-home-spotlight .ch24-home-spotlight__slide {
    min-width: 400px
  }
}

@screen sm {
  .ch24-home-spotlight .ch24-home-spotlight__slide {
    min-width: 300px
  }

  .ch24-home-spotlight .ch24-home-spotlight__slide--large {
    min-width: 550px
  }
}

.ch24-home-spotlight .ch24-home-spotlight__slide:first-child {
  -webkit-transition-delay: .15s;
  transition-delay: .15s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(2) {
  -webkit-transition-delay: .3s;
  transition-delay: .3s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(3) {
  -webkit-transition-delay: .45s;
  transition-delay: .45s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(4) {
  -webkit-transition-delay: .6s;
  transition-delay: .6s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(5) {
  -webkit-transition-delay: .75s;
  transition-delay: .75s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(6) {
  -webkit-transition-delay: .9s;
  transition-delay: .9s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(7) {
  -webkit-transition-delay: 1.05s;
  transition-delay: 1.05s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(8) {
  -webkit-transition-delay: 1.2s;
  transition-delay: 1.2s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(9) {
  -webkit-transition-delay: 1.35s;
  transition-delay: 1.35s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:nth-child(10) {
  -webkit-transition-delay: 1.5s;
  transition-delay: 1.5s
}

.ch24-home-spotlight .ch24-home-spotlight__slide:last-child {
  padding-right: 0
}

.ch24-home-spotlight .ch24-home-spotlight__slide-tile {
  border: 0
}

.ch24-home-spotlight .ch24-home-spotlight__slide-tile .ch24-tile__body {
  padding-bottom: 16px;
  padding-top: 16px
}

.ch24-home-spotlight .ch24-home-spotlight__slide-tile .ch24-tile__footer {
  padding-bottom: 16px
}

.ch24-home-spotlight .ch24-home-spotlight__slide-tile:hover {
  color: #fff
}

.ch24-home-spotlight .ch24-home-spotlight__slide-description,
.ch24-home-spotlight .ch24-home-spotlight__slide-tile .ch24-button {
  color: var(--blue-600);
  font-size: 14px;
  line-height: 16px;
}

@screen sm {

  .ch24-home-spotlight .ch24-home-spotlight__slide-tile--large .ch24-button,
  .ch24-home-spotlight .ch24-home-spotlight__slide-tile--large .ch24-home-spotlight__slide-description {
    font-size: 14px;
    line-height: 20px
  }
}

.ch24-home-spotlight .ch24-home-spotlight__carousel.is-visible .ch24-home-spotlight__slide {
  opacity: 1
}

.ch24-home-spotlight .ch24-home-spotlight__carousel {
  position: relative;
}

.ch24-home-spotlight .ch24-tile.ch24-tile--type-icon .ch24-tile__header,
.ch24-home-spotlight .ch24-tile.ch24-tile--type-image .ch24-tile__header {
  background-color: var(--gray-200)
}

.ch24-home-spotlight .ch24-tile.ch24-tile--type-icon .ch24-tile__header img,
.ch24-home-spotlight .ch24-tile.ch24-tile--type-image .ch24-tile__header img {
  max-width: none;
  width: 100%
}

.ch24-home-spotlight .ch24-tile.ch24-tile--type-icon .ch24-home-spotlight__slide-description,
.ch24-home-spotlight .ch24-tile.ch24-tile--type-image .ch24-home-spotlight__slide-description {
  color: #666666;
}

.ch24-home-spotlight .ch24-tile.ch24-tile--type-icon .ch24-tile__header {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 102px;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 102px
}



.ch24-home-teams__footer-cta,
.ch24-home-locations__footer-cta,
.ch24-home-social-engagement__footer-cta {
  font-size: 16px;
  line-height: 24px;
  margin-top: 40px;
}

.ch24-home-locations__cols {
  margin: 40px 0 25px
}

@screen sm {
  .ch24-home-locations__cols {
    margin: 36px 0
  }
}

.ch24-home-locations__cols-col {
  margin-bottom: 30px;
}

@media (min-width: 25em) {
  .ch24-home-locations__cols-col {
    padding-right: 20px;
  }
}

@media (max-width: theme('screens.sm.max-width')) {
  .ch24-home-locations .ch24-grid-cols :nth-child(4) {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1
  }
}

@media (max-width: theme('screens.sm.max-width')) {
  .ch24-home-locations .ch24-grid-cols :nth-child(2) {
    -webkit-box-ordinal-group: 3;
    -ms-flex-order: 2;
    order: 2
  }
}

@screen sm {
  .ch24-home-working-at-ch24__overline {
    margin-bottom: 24px;
    text-align: left
  }
}

@screen sm {
  .ch24-home-working-at-ch24__headline {
    text-align: left
  }
}

.ch24-home-locations,
.ch24-home-teams,
.ch24-home-social-engagement {
  margin-top: 40px;
  margin-bottom: 40px;

  .ch24-link {
    @apply transition-colors;
    color: var(--blue-600);

    &:hover {
      color: #C05702;
    }
  }

  @screen xs {
    margin-top: 70px;
    margin-bottom: 70px;
  }
}

.ch24-home-social-engagement p {
  @apply [&_a]:transition-colors [&_a]:text-brand [&_a:hover]:text-[#c05702];
}

.ch24-home-working-at-ch24 {
  margin-top: 40px;
  padding-bottom: 40px;

  @screen xs {
    margin-top: 70px;
    padding-bottom: 70px;
  }
}

.ch24-home-teams__cols-col:not(.ch24-home-teams__cols-col:nth-child(-n+4)) {
  display: none;
}

.ch24-home-locations__cols-col:not(.ch24-home-locations__cols-col:nth-child(-n+6)) {
  display: none;
}

.ch24-home-working-at-ch24 {
  background-color: var(--gray-200);
  position: relative;
  z-index: 0
}

.ch24-home-working-at-ch24__container {
  position: relative;
  z-index: 10
}

.ch24-home-working-at-ch24:before {
  background-color: #fff;
  content: "";
  height: 600px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1
}

.ch24-home-working-at-ch24__tiles {
  margin-top: 20px
}

@screen sm {
  .ch24-home-working-at-ch24__tiles {
    margin-top: 40px
  }
}

@screen sm {
  .ch24-home-working-at-ch24__tiles {
    margin-top: 20px
  }
}

.ch24-home-working-at-ch24 .ch24-layout-two-card {
  margin-top: 0;
  padding-left: 0;
  padding-right: 0
}

.ch24-home-working-at-ch24 .ch24-layout-two-card__item {
  background-color: transparent
}

@screen md {
  .ch24-home-working-at-ch24 .block-work_at_ch24_two_card_bottom .ch24-layout-two-card__item {
    margin-bottom: 0;
  }
}

@media (max-width: theme('screens.sm.max-width')) {
  .ch24-home-working-at-ch24 .block-work_at_ch24_two_card_bottom .ch24-layout-two-card__item:last-child {
    margin-bottom: 0;
  }
}

.ch24-home-working-at-ch24__title {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  margin: 0 auto;
}

.ch24-overline {
  margin-bottom: 10px;
  margin-top: 0;

  @screen md {
    margin-bottom: 15px;
  }
}
