.ch24-list {
  list-style: inside;
  font-size: 16px;
  margin-left: 15px;
}

.ch24-list li:not(:first-child) {
  margin-top: 1rem;
}

.ch24-list--outside {
  list-style: outside;
}

.ch24-block {
  li:not(:first-child) {
    margin-top: 1rem;
  }

  ul {
    list-style: inside;
    font-size: 16px;
  }
}
