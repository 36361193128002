.ch24-story-panel-wrapper {
    @apply overflow-hidden rounded-md;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap
}

a.ch24-story-panel-wrapper, a .ch24-story-panel, .story-panel--youtube {
    @apply shadow transition-shadow;

    &:hover {
        @apply shadow-lg;
    }
}

.ch24-story-panel {
    @apply overflow-hidden rounded-md;
    background-position: top;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
}

.ch24-story-panel--large {
    color: #fff;
    min-height: 200px
}

@screen xs {
    .ch24-story-panel--large {
        min-height: 300px
    }
}

@screen sm {
    .ch24-story-panel--large {
        min-height: 400px
    }
}

.ch24-story-panel--large .ch24-story-panel__content {
    bottom: 30px;
    left: 20px;
    right: 20px;
    z-index: 1
}

@screen md {
    .ch24-story-panel--large .ch24-story-panel__content {
        bottom: 60px;
        left: 40px;
        max-width: 400px;
        right: 20px
    }
}

/*@media (min-width: theme('screens.sm')) and (max-width: theme('screens.md')) {
    .ch24-story-panel--large .ch24-story-panel__content {
        max-width: 300px
    }
}*/

.ch24-story-panel--large .ch24-story-panel__link {
    bottom: 20px;
    left: 20px;
    text-shadow: none;
    font-size: 16px;
}

@screen md {
    .ch24-story-panel--large .ch24-story-panel__link {
        bottom: 40px;
        left: 40px;
        font-size: 20px;
    }
}

.ch24-story-panel--large .ch24-story-panel__overlay {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(rgba(0, 0, 0, .6)));
    background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, .6))
}

.ch24-story-panel--large .ch24-story-panel__title {
    text-shadow: none
}

.ch24-story-panel--small {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 251px
}

@media (max-width: theme('screens.sm.max-width')) {
    .ch24-story-panel--small {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        min-height: 181px
    }
}

@media (max-width: theme('screens.sm.max-width')) {
    .ch24-story-panel--small {
        min-height: 210px
    }
}

.ch24-story-panel--small .ch24-story-panel__content {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    flex-direction: column;
    overflow: hidden;
    padding-bottom: 40px;
    position: relative
}

.ch24-story-panel--small .ch24-story-panel__content,
.ch24-story-panel--small .ch24-story-panel__content--no-image {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    z-index: 1
}

.ch24-story-panel--small .ch24-story-panel__content--no-image {
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px 20px 40px;
    width: 100%
}

.ch24-story-panel--small .ch24-story-panel__content--no-image .ch24-story-panel__title {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    font-size: 22px;
    height: 170px;
    margin: 0;
    padding: 0
}

/* ToDo
@screen sm and (max-width: 62.49em) {
    .ch24-story-panel--small .ch24-story-panel__content--no-image .ch24-story-panel__title {
        font-size: 17px
    }
}
*/

.ch24-story-panel--small .ch24-story-panel__content--quote {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 20px;
    width: 100%;
    z-index: 1
}

.ch24-story-panel--small .ch24-story-panel__content--quote .ch24-story-panel__title {
    font-size: 17px;
    padding: 0
}

.ch24-story-panel--small .ch24-story-panel__title {
    font-size: 17px;
    margin-bottom: 0;
    padding: 20px
}

@media (max-width: theme('screens.sm.max-width')) {
    .ch24-story-panel--small .ch24-story-panel__title {
        font-size: 22px
    }
}

.ch24-story-panel--small .ch24-story-panel__title--light-bg {
    color: #202124
}

.ch24-story-panel--small .ch24-story-panel__title--dark-bg {
    color: #fff
}

.ch24-story-panel--small .ch24-story-panel__image {
    background-position: 50%;
    background-size: cover;
    display: block;
    -ms-flex: 0 1 auto;
    -webkit-box-flex: 0;
    flex: 0 1 auto;
    height: 110px;
    max-height: 122px;
    width: 100%
}

@media (max-width: theme('screens.sm.max-width')) {
    .ch24-story-panel--small .ch24-story-panel__image {
        display: none
    }
}

.ch24-story-panel--small .ch24-story-panel__link {
    bottom: 20px;
    left: 20px
}

.ch24-story-panel--small .ch24-story-panel__link--light-bg {
    color: #1967d2
}

.ch24-story-panel--small .ch24-story-panel__link--dark-bg {
    color: #fff
}

.ch24-story-panel--video {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    right: 0;
    top: 0
}

.ch24-story-panel--video,
.ch24-story-panel__content {
    bottom: 0;
    left: 0;
    position: absolute;
    z-index: 1
}

.ch24-story-panel__content--video {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 20px;
    width: 100%
}

.ch24-story-panel__content--video h2 {
    text-shadow: none
}

.ch24-story-panel__overlay {
    bottom: 0;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 0;
}

.ch24-story-panel__overlay--gradient {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(rgba(0, 0, 0, .6)));
    background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, .6));
    z-index: 0
}

.ch24-story-panel__overlay--gradient-extra-light {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(rgba(0, 0, 0, .03)));
    background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, .03))
}

.ch24-story-panel__overlay--gradient-light {
    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(40%, transparent), to(rgba(0, 0, 0, .2)));
    background-image: linear-gradient(180deg, transparent 40%, rgba(0, 0, 0, .2))
}

.ch24-story-panel__title {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 22px;
    font-weight: 300;
    line-height: 1.3;
    z-index: 1
}

@screen md {
    .ch24-story-panel__title {
        font-size: 30px
    }
}

.ch24-story-panel__title--video {
    font-size: 14px;
    line-height: normal;
    margin: 0;
    z-index: 1
}

.ch24-story-panel__link {
    align-items: center;
    display: flex;
    font-size: 16px;
    font-weight: 400;
    z-index: 1;

    @screen md {
        max-width: 80%;
    }

    .ch24-story-panel--subtitle {
        font-family: Arial, Helvetica, sans-serif;
        flex-shrink: 1;
        font-size: 18px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .ch24-story-panel__arrow {
        flex-grow: 0;
        flex-shrink: 0;
    }
}

.ch24-story-panel__tag {
    font-size: 13px;
    padding-top: 10px;
    z-index: 1
}

.ch24-story-panel__tag--light-bg {
    color: #202124
}

.ch24-story-panel__tag--dark-bg {
    color: #fff
}

.ch24-story-panel__link-lang {
    font-size: 70%;
    vertical-align: middle
}

.ch24-story-panel__arrow {
    height: 18px;
    width: 18px;
    margin-left: 5px;
}

.ch24-story-panel--youtube {
    >iframe {
        @apply rounded-md;
        width: 100%;
        height: 100%;
        position: absolute;
    }
}
