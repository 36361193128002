@import "../abstract/var";

.ch24-find-job-bar {
  background-color: white;
  border-bottom: 1px solid var(--gray-300);
  width: 100%;
  z-index: 9;
  padding-top: $menu-mobile-height;

  .ch24-container {
    padding-top: 16px;
    padding-bottom: 16px;
  }

  @screen lg {
    position: fixed;

    .ch24-container {
      align-items: center;
      display: flex;
      height: $find-job-bar-desktop-height;
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  @screen fullmenu {
    padding-top: $menu-desktop-height;
  }

  a:hover {
    .ch24-find-job-bar__label {
      color: #C05702;
    }

    svg {
      fill: #C05702 !important;
    }
  }

  svg {
    @apply transition-colors;
  }

  &__label {
    @apply transition-colors;
    margin-left: 1.25rem;
    font-size: 16px;
    color: var(--blue-600);
  }
}

.ch24-find-job-bar--search-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;

  @screen sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    padding-left: $search-page-side-bar-padding-left-desktop;
  }
}
