.ch24-spacer {
    height: 80px;
}

.ch24-spacer--sm {
    height: 60px;
}

.ch24-spacer--xs {
    height: 40px;
}
