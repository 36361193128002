@import "../abstract/var";

$mobile-navigation-height: 3rem;

.main-header {
    background: var(--blue-800);
    color: #fff;
    display: flex;
    flex-shrink: 0;
    font-size: 1rem;
    height: $mobile-navigation-height;
    width: 100%;
    z-index: 10000;

    &--container {
        background: inherit;
        justify-content: space-between;
        width: 100%;
    }

    &--container,
    &--section,
    &--item {
        color: currentColor;
        display: flex;
    }

    &--toggle {
        align-items: center;
        color: currentColor;
        cursor: pointer;
        display: flex;
        font-size: 1em;
        font-weight: 400;
        line-height: 1.5;
        padding: 0;
        text-decoration: none !important;
        white-space: nowrap;
    }

    &--logo {
        color: #fff;
        justify-content: center;
        align-items: center;
        padding: 1rem 0;
        margin-left: 20px;

        .svg-logo {
            font-size: 1.333rem;
            height: 1.5em;
            width: 130px;
        }
    }

    &--navigation {
        @apply justify-between;

        background: var(--blue-800);
        color: #fff;
        display: none;
        flex-direction: column;
        font-size: 1em;
        opacity: 0;
        max-width: 280px;
        position: fixed;
        top: $mobile-navigation-height;
        left: 0;
        bottom: 0;
        -webkit-transition: -webkit-transform .35s cubic-bezier(.24, 1, .32, 1);
        transition: transform .35s cubic-bezier(.24, 1, .32, 1);
        will-change: transform;
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        z-index: -1;
        width: 100%;
    }
}

.main-menu {
    ul {
        &>li {
            position: relative;

            &:hover {
                background-color: var(--blue-700);
            }

            &>a {
                align-items: center;
                color: currentColor;
                display: flex;
                flex-grow: 1;
                font-size: 1rem;
                line-height: 1.5;
                padding: 1em;
                position: relative;
                text-decoration: none;
                white-space: nowrap;
            }

            &.current>a {
                font-weight: 700;

                &::before {
                    border: 0 solid #063773;
                    border-width: 0 0 0 .429rem;
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                }
            }
        }
    }

    &--section {
        display: flex;
        flex-direction: column;
        margin: 1rem 0 0;
    }
}

.language-switcher--container {
    @apply flex items-center;
    height: 100%;

    ul {
        @apply flex items-center;
        height: 100%;
        gap: 1rem;

        @screen fullmenu {
            gap: 0;
        }

        &>li {
            position: relative;
            text-transform: uppercase;
            height: 100%;

            &:hover {
                background-color: var(--blue-700);
            }

            &.current-lang>a {
                font-weight: 700 !important;
            }

            &>a {
                align-items: center;
                color: currentColor;
                display: flex;
                flex-grow: 1;
                font-size: 1rem;
                line-height: 1.5;
                height: 100%;
                position: relative;
                text-decoration: none;
                white-space: nowrap;
                padding: 0 0.5rem;

                @screen fullmenu {
                    padding: 1rem;
                }
            }
        }
    }
}

/* when mobile is expanded */
.mainmenu-is-visible {
    .main-header--navigation {
        opacity: 1;
        display: flex;
        transform: translateZ(0);
    }
}

.svg-logo {
    fill: currentColor;
}


/* if main-menu is expanded */
@screen fullmenu {

    .main-content {
        padding-top: 3rem;
        transition: padding .3s;
    }

    .main-header--toggle-item {
        display: none;
    }

    .main-header {
        height: $menu-desktop-height;
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;

        &--container {
            background: transparent;
            width: 100%;
        }

        &--navigation {
            background: transparent;
            color: #fff;
            flex-direction: row;
            max-width: 100%;
            opacity: 1 !important;
            overflow: visible !important;
            padding: 0;
            position: relative;
            top: auto;
            right: auto;
            bottom: auto;
            transform: translateX(0) !important;
            transition: all 0s !important;
            display: flex;
            width: 100%;
            margin-left: 48px;
            z-index: 2;
        }

        &--logo {
            margin-left: 0;

            .svg-logo {
                font-size: 1.333rem;
                height: 50px;
                width: 170px;
            }
        }
    }

    .main-menu {
        box-shadow: none;
        display: flex;
        flex-direction: row;
        height: auto;
        padding: 0;
        width: 80%;

        .main-menu--list {
            display: flex;
            flex-direction: row;

            &>li+li {
                margin-left: 1rem;
            }

            &>li {
                background: transparent;
                display: flex;

                &.main-menu-item--mobile-only {
                    @screen lg {
                        display: none;
                    }
                }

                &.main-menu-item--active {
                    &::after {
                        background-color: var(--yellow-400);
                        bottom: 0;
                        content: '';
                        display: block;
                        height: 5px;
                        position: absolute;
                        width: 100%;
                    }
                }
            }
        }

        ul>li>a {
            padding: .75rem 1rem;
        }

        .main-menu--section {
            justify-content: center;
            margin: 0 0 0 2rem;

            &-cta>.btn {
                font-size: 1.143rem;
                min-width: 10rem;
                transition: min-width .3s, font-size .3s;
            }
        }

        ul>li.current>a::before {
            border-color: #fff;
        }

        .main-menu--list>li+li {
            margin-left: 2rem;
        }
        .main-menu--list>li.current>a {
            font-weight: 500;
        }

        .main-menu--list>li.current>a::before {
            border-width: 0 0 .429rem 0;
            top: auto;
            bottom: 0;
            left: 0;
            right: 0;
        }

        .main-menu--list>li>a {
            font-size: 1rem;
        }

        ul>li>a {
            padding: .75rem;
        }
    }
}
