.ch24-quote {
  padding-top: 20px;
  padding-bottom: 20px;

  &__image {
    & > img {
      object-fit: cover;
      border-radius: 100%;
      height: 100px;
      width: 100px;
      margin: 0 auto;
    }

    @screen sm {
      display: block;
      flex-shrink: 0;

      >img {
        margin: 0;
        height: 150px;
        width: 150px;
      }
    }
  }

  &__container {
    @extend .ch24-container;
    position: relative;

    @screen sm {
      padding-bottom: 0;
      padding-right: 7rem;
      padding-left: 7rem;
      max-width: 1100px;
      margin: 0 auto;
    }
  }

  &__wrapper {
    position: relative;
    padding-top: 20px;
    padding-bottom: 50px;

    @screen sm {
      display: flex;
      gap: 40px;
      justify-content: center;
      padding-top: 40px;
      padding-bottom: 20px;
    }
  }

  &__symbol-right {
    left: 5px;
    bottom: 0;
  }

  &__symbol-left {
    right: 5px;
    top: 0;
  }

  &__symbol-left,
  &__symbol-right {
    content: '';
    position: absolute;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center center;

    >svg {
      width: 40px;
      height: 35px;
    }

    @screen sm {
      >svg {
        width: 74px;
        height: 65px;
      }
    }
  }

  &__text-container {
    margin: 0 auto;
    text-align: center;

    @screen sm {
      max-width: 600px;
      margin: 0;
      text-align: left;
    }
  }

  &__title {
    font-size: 18px !important;
    font-weight: bold !important;

    @screen sm {
      font-size: inherit !important;
      font-size: 2.5rem;
    }
  }

  &__person-description {
    font-size: 12px;
    color: var(--gray-500)
  }
}
