.ch24-iframe {
    @apply rounded-md;
    height: 100%;
    min-height: 200px;
    margin: 0 auto;
    width: 100%;

    @screen xs {
        min-height: 500px;
    }

    @screen md {
        height: 506.25px;
        width: 900px;
    }
}

.ch24-youtube-embed iframe {
    @extend .ch24-iframe
}
