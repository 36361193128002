.ch24-tech-stack {

  &__list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
    text-align: center;
    list-style: none;
    margin: -10px;

    @screen md {
      margin: -1rem;
    }
  }


  &__listitem {
    display: block;
    height: 40px;
    margin: 10px;
    opacity: .7;
    vertical-align: top;

    @screen md {
      margin: 1rem;
    }
  }
}
