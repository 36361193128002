@import '../layout/text';

.ch24-contact-blocks .ch24-block {
  &:first-child {
    margin-top: 20px;
  }

  .ch24-contactBlock {
    &-title {
      @extend .ch24-headline;
      @extend .ch24-headline--headline-3;
    }

    &-text {
      @extend .has-ch24-paragraphs;
      margin-top: 15px;

      a {
        color: #005ea8;
        font-size: 16px;
      }
    }
  }
}
