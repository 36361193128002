.ch24-tile {
    @apply transition-transform;
    background-color: #fff;
    border: 1px solid #dadce0;
    border-radius: 8px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    height: 100%;
    min-height: 240px;
    overflow: hidden;
    position: relative;

    &:hover {
        transform: scale(1.008);
    }
}

.ch24-tile__header {
    min-height: 200px;
}

@screen sm {
    .ch24-tile {
        min-height: 310px;
    }

    .ch24-tile__header {
        height: 170px;
    }
}


.ch24-tile__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    position: relative;
    z-index: 10
}

.ch24-tile__body {
    padding: 24px
}

.ch24-tile__body ol,
.ch24-tile__body p,
.ch24-tile__body ul {
    color: #5f6368
}

.ch24-tile__body ol:last-child,
.ch24-tile__body p:last-child,
.ch24-tile__body ul:last-child {
    margin-bottom: 0
}

.ch24-tile__footer {
    margin-top: auto;
    padding: 0 24px 24px
}

.ch24-tile__content--bottom
.ch24-tile__footer {
    margin-top: 0
}

.ch24-tile__footer-link,
.ch24-tile__footer-links {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.ch24-tile__footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -12px
}

.ch24-tile__footer-link {
    margin: 0 12px
}

.ch24-tile__footer {
    margin-top: auto;
    padding: 0 24px 24px
}

.ch24-tile__footer-link,
.ch24-tile__footer-links {
    list-style-type: none;
    margin: 0;
    padding: 0
}

.ch24-tile__footer-links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: 0 -12px
}

.ch24-tile__footer-link {
    margin: 0 12px
}

.ch24-tile__background {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    z-index: 1
}

.ch24-tile__background-dark-overlay:after {
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, .6)), color-stop(50%, rgba(0, 0, 0, .4)), color-stop(75%, transparent));
    background-image: linear-gradient(0deg, rgba(0, 0, 0, .6) 0, rgba(0, 0, 0, .4) 50%, transparent 75%);
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2
}

.ch24-tile--type-full-image {
    border: none
}

.ch24-tile--is-dark {
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5)
}

.ch24-tile--is-dark .ch24-headline,
.ch24-tile--is-dark .ch24-tile__body ol,
.ch24-tile--is-dark .ch24-tile__body p,
.ch24-tile--is-dark .ch24-tile__body ul,
.ch24-tile--is-dark .ch24-tile__footer-button {
    color: inherit
}


.ch24-tile__header img {
    display: inline-block;
    height: auto;
    max-width: 100%;
    vertical-align: middle
}

@supports ((-o-object-fit:cover) or (object-fit:cover)) {
    .ch24-tile__background img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%
    }
}

@supports ((-o-object-fit:cover) or (object-fit:cover)) {
    .ch24-tile__header img {
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%
    }
}

.ch24-tile__content--bottom {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.ch24-tile--is-dark {
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, .5)
}

.ch24-tile--is-dark .ch24-headline,
.ch24-tile--is-dark .ch24-tile__body ol,
.ch24-tile--is-dark .ch24-tile__body p,
.ch24-tile--is-dark .ch24-tile__body ul,
.ch24-tile--is-dark .ch24-tile__footer-button {
    color: inherit
}
