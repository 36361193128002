.ch24-grid {
    @apply flex flex-wrap w-full;
    gap: 20px;

    @screen xs {
        @apply flex-nowrap;
    }
}

.ch24-grid__col {
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 100%
}

@supports (display:grid) {
    .ch24-grid-cols {
        display: grid;
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    @screen sm {
        .ch24-grid-cols {
            grid-template-columns:repeat(12, minmax(5px, 1fr))
        }
    }

    @screen md {
        .ch24-grid-cols {
            grid-template-columns:repeat(24, minmax(5px, 1fr))
        }
    }
}

.ch24-grid-cols__col {
    display: block
}

@supports (display:grid) {
    .ch24-grid-cols__col--span-1 {
        display: block;
        grid-column-end: span 1
    }

    .ch24-grid-cols__col--span-1 .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-2 {
        display: block;
        grid-column-end: span 2
    }

    .ch24-grid-cols__col--span-2 .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-3 {
        display: block;
        grid-column-end: span 3
    }

    .ch24-grid-cols__col--span-3 .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-4 {
        display: block;
        grid-column-end: span 4
    }

    .ch24-grid-cols__col--span-4 .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--align-top {
        -ms-flex-item-align: start;
        align-self: start
    }

    .ch24-grid-cols__col--align-middle {
        -ms-flex-item-align: center;
        align-self: center
    }

    .ch24-grid-cols__col--align-bottom {
        -ms-flex-item-align: end;
        align-self: end
    }

    .ch24-grid-cols__col--span-0, .ch24-grid-cols__col--span-0-small {
        display: none
    }

    @screen sm {
        .ch24-grid-cols__col--span-1-small {
            display: block;
            grid-column-end: span 1
        }

        .ch24-grid-cols__col--span-1-small .ch24-grid-cols {
            grid-template-columns:repeat(1, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-2-small {
            display: block;
            grid-column-end: span 2
        }

        .ch24-grid-cols__col--span-2-small .ch24-grid-cols {
            grid-template-columns:repeat(2, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-3-small {
            display: block;
            grid-column-end: span 3
        }

        .ch24-grid-cols__col--span-3-small .ch24-grid-cols {
            grid-template-columns:repeat(3, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-4-small {
            display: block;
            grid-column-end: span 4
        }

        .ch24-grid-cols__col--span-4-small .ch24-grid-cols {
            grid-template-columns:repeat(4, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-5-small {
            display: block;
            grid-column-end: span 5
        }

        .ch24-grid-cols__col--span-5-small .ch24-grid-cols {
            grid-template-columns:repeat(5, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-6-small {
            display: block;
            grid-column-end: span 6
        }

        .ch24-grid-cols__col--span-6-small .ch24-grid-cols {
            grid-template-columns:repeat(6, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-7-small {
            display: block;
            grid-column-end: span 7
        }

        .ch24-grid-cols__col--span-7-small .ch24-grid-cols {
            grid-template-columns:repeat(7, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-8-small {
            display: block;
            grid-column-end: span 8
        }

        .ch24-grid-cols__col--span-8-small .ch24-grid-cols {
            grid-template-columns:repeat(8, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-9-small {
            display: block;
            grid-column-end: span 9
        }

        .ch24-grid-cols__col--span-9-small .ch24-grid-cols {
            grid-template-columns:repeat(9, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-10-small {
            display: block;
            grid-column-end: span 10
        }

        .ch24-grid-cols__col--span-10-small .ch24-grid-cols {
            grid-template-columns:repeat(10, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-11-small {
            display: block;
            grid-column-end: span 11
        }

        .ch24-grid-cols__col--span-11-small .ch24-grid-cols {
            grid-template-columns:repeat(11, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-12-small {
            display: block;
            grid-column-end: span 12
        }

        .ch24-grid-cols__col--span-12-small .ch24-grid-cols {
            grid-template-columns:repeat(12, minmax(5px, 1fr))
        }
    }@screen sm {
    .ch24-grid-cols__col--span-1 {
        display: block;
        grid-column-end: span 1
    }

    .ch24-grid-cols__col--span-1 .ch24-grid-cols {
        grid-template-columns:repeat(1, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-2 {
        display: block;
        grid-column-end: span 2
    }

    .ch24-grid-cols__col--span-2 .ch24-grid-cols {
        grid-template-columns:repeat(2, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-3 {
        display: block;
        grid-column-end: span 3
    }

    .ch24-grid-cols__col--span-3 .ch24-grid-cols {
        grid-template-columns:repeat(3, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-4 {
        display: block;
        grid-column-end: span 4
    }

    .ch24-grid-cols__col--span-4 .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-5 {
        display: block;
        grid-column-end: span 5
    }

    .ch24-grid-cols__col--span-5 .ch24-grid-cols {
        grid-template-columns:repeat(5, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-6 {
        display: block;
        grid-column-end: span 6
    }

    .ch24-grid-cols__col--span-6 .ch24-grid-cols {
        grid-template-columns:repeat(6, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-7 {
        display: block;
        grid-column-end: span 7
    }

    .ch24-grid-cols__col--span-7 .ch24-grid-cols {
        grid-template-columns:repeat(7, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-8 {
        display: block;
        grid-column-end: span 8
    }

    .ch24-grid-cols__col--span-8 .ch24-grid-cols {
        grid-template-columns:repeat(8, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-9 {
        display: block;
        grid-column-end: span 9
    }

    .ch24-grid-cols__col--span-9 .ch24-grid-cols {
        grid-template-columns:repeat(9, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-10 {
        display: block;
        grid-column-end: span 10
    }

    .ch24-grid-cols__col--span-10 .ch24-grid-cols {
        grid-template-columns:repeat(10, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-11 {
        display: block;
        grid-column-end: span 11
    }

    .ch24-grid-cols__col--span-11 .ch24-grid-cols {
        grid-template-columns:repeat(11, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-12 {
        display: block;
        grid-column-end: span 12
    }

    .ch24-grid-cols__col--span-12 .ch24-grid-cols {
        grid-template-columns:repeat(12, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-0-medium {
        display: none
    }

    .ch24-grid-cols__col--span-1-medium {
        display: block;
        grid-column-end: span 1
    }

    .ch24-grid-cols__col--span-1-medium .ch24-grid-cols {
        grid-template-columns:repeat(1, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-2-medium {
        display: block;
        grid-column-end: span 2
    }

    .ch24-grid-cols__col--span-2-medium .ch24-grid-cols {
        grid-template-columns:repeat(2, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-3-medium {
        display: block;
        grid-column-end: span 3
    }

    .ch24-grid-cols__col--span-3-medium .ch24-grid-cols {
        grid-template-columns:repeat(3, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-4-medium {
        display: block;
        grid-column-end: span 4
    }

    .ch24-grid-cols__col--span-4-medium .ch24-grid-cols {
        grid-template-columns:repeat(4, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-5-medium {
        display: block;
        grid-column-end: span 5
    }

    .ch24-grid-cols__col--span-5-medium .ch24-grid-cols {
        grid-template-columns:repeat(5, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-6-medium {
        display: block;
        grid-column-end: span 6
    }

    .ch24-grid-cols__col--span-6-medium .ch24-grid-cols {
        grid-template-columns:repeat(6, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-7-medium {
        display: block;
        grid-column-end: span 7
    }

    .ch24-grid-cols__col--span-7-medium .ch24-grid-cols {
        grid-template-columns:repeat(7, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-8-medium {
        display: block;
        grid-column-end: span 8
    }

    .ch24-grid-cols__col--span-8-medium .ch24-grid-cols {
        grid-template-columns:repeat(8, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-9-medium {
        display: block;
        grid-column-end: span 9
    }

    .ch24-grid-cols__col--span-9-medium .ch24-grid-cols {
        grid-template-columns:repeat(9, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-10-medium {
        display: block;
        grid-column-end: span 10
    }

    .ch24-grid-cols__col--span-10-medium .ch24-grid-cols {
        grid-template-columns:repeat(10, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-11-medium {
        display: block;
        grid-column-end: span 11
    }

    .ch24-grid-cols__col--span-11-medium .ch24-grid-cols {
        grid-template-columns:repeat(11, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-12-medium {
        display: block;
        grid-column-end: span 12
    }

    .ch24-grid-cols__col--span-12-medium .ch24-grid-cols {
        grid-template-columns:repeat(12, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-13-medium {
        display: block;
        grid-column-end: span 13
    }

    .ch24-grid-cols__col--span-13-medium .ch24-grid-cols {
        grid-template-columns:repeat(13, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-14-medium {
        display: block;
        grid-column-end: span 14
    }

    .ch24-grid-cols__col--span-14-medium .ch24-grid-cols {
        grid-template-columns:repeat(14, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-15-medium {
        display: block;
        grid-column-end: span 15
    }

    .ch24-grid-cols__col--span-15-medium .ch24-grid-cols {
        grid-template-columns:repeat(15, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-16-medium {
        display: block;
        grid-column-end: span 16
    }

    .ch24-grid-cols__col--span-16-medium .ch24-grid-cols {
        grid-template-columns:repeat(16, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-17-medium {
        display: block;
        grid-column-end: span 17
    }

    .ch24-grid-cols__col--span-17-medium .ch24-grid-cols {
        grid-template-columns:repeat(17, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-18-medium {
        display: block;
        grid-column-end: span 18
    }

    .ch24-grid-cols__col--span-18-medium .ch24-grid-cols {
        grid-template-columns:repeat(18, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-19-medium {
        display: block;
        grid-column-end: span 19
    }

    .ch24-grid-cols__col--span-19-medium .ch24-grid-cols {
        grid-template-columns:repeat(19, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-20-medium {
        display: block;
        grid-column-end: span 20
    }

    .ch24-grid-cols__col--span-20-medium .ch24-grid-cols {
        grid-template-columns:repeat(20, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-21-medium {
        display: block;
        grid-column-end: span 21
    }

    .ch24-grid-cols__col--span-21-medium .ch24-grid-cols {
        grid-template-columns:repeat(21, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-22-medium {
        display: block;
        grid-column-end: span 22
    }

    .ch24-grid-cols__col--span-22-medium .ch24-grid-cols {
        grid-template-columns:repeat(22, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-23-medium {
        display: block;
        grid-column-end: span 23
    }

    .ch24-grid-cols__col--span-23-medium .ch24-grid-cols {
        grid-template-columns:repeat(23, minmax(5px, 1fr))
    }

    .ch24-grid-cols__col--span-24-medium {
        display: block;
        grid-column-end: span 24
    }

    .ch24-grid-cols__col--span-24-medium .ch24-grid-cols {
        grid-template-columns:repeat(24, minmax(5px, 1fr))
    }
}
    @screen md {

        .ch24-grid-cols__col--span-0-large {
            display: none
        }

        .ch24-grid-cols__col--span-1-large {
            display: block;
            grid-column-end: span 1
        }

        .ch24-grid-cols__col--span-1-large .ch24-grid-cols {
            grid-template-columns:repeat(1, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-2-large {
            display: block;
            grid-column-end: span 2
        }

        .ch24-grid-cols__col--span-2-large .ch24-grid-cols {
            grid-template-columns:repeat(2, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-3-large {
            display: block;
            grid-column-end: span 3
        }

        .ch24-grid-cols__col--span-3-large .ch24-grid-cols {
            grid-template-columns:repeat(3, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-4-large {
            display: block;
            grid-column-end: span 4
        }

        .ch24-grid-cols__col--span-4-large .ch24-grid-cols {
            grid-template-columns:repeat(4, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-5-large {
            display: block;
            grid-column-end: span 5
        }

        .ch24-grid-cols__col--span-5-large .ch24-grid-cols {
            grid-template-columns:repeat(5, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-6-large {
            display: block;
            grid-column-end: span 6
        }

        .ch24-grid-cols__col--span-6-large .ch24-grid-cols {
            grid-template-columns:repeat(6, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-7-large {
            display: block;
            grid-column-end: span 7
        }

        .ch24-grid-cols__col--span-7-large .ch24-grid-cols {
            grid-template-columns:repeat(7, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-8-large {
            display: block;
            grid-column-end: span 8
        }

        .ch24-grid-cols__col--span-8-large .ch24-grid-cols {
            grid-template-columns:repeat(8, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-9-large {
            display: block;
            grid-column-end: span 9
        }

        .ch24-grid-cols__col--span-9-large .ch24-grid-cols {
            grid-template-columns:repeat(9, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-10-large {
            display: block;
            grid-column-end: span 10
        }

        .ch24-grid-cols__col--span-10-large .ch24-grid-cols {
            grid-template-columns:repeat(10, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-11-large {
            display: block;
            grid-column-end: span 11
        }

        .ch24-grid-cols__col--span-11-large .ch24-grid-cols {
            grid-template-columns:repeat(11, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-12-large {
            display: block;
            grid-column-end: span 12
        }

        .ch24-grid-cols__col--span-12-large .ch24-grid-cols {
            grid-template-columns:repeat(12, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-13-large {
            display: block;
            grid-column-end: span 13
        }

        .ch24-grid-cols__col--span-13-large .ch24-grid-cols {
            grid-template-columns:repeat(13, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-14-large {
            display: block;
            grid-column-end: span 14
        }

        .ch24-grid-cols__col--span-14-large .ch24-grid-cols {
            grid-template-columns:repeat(14, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-15-large {
            display: block;
            grid-column-end: span 15
        }

        .ch24-grid-cols__col--span-15-large .ch24-grid-cols {
            grid-template-columns:repeat(15, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-16-large {
            display: block;
            grid-column-end: span 16
        }

        .ch24-grid-cols__col--span-16-large .ch24-grid-cols {
            grid-template-columns:repeat(16, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-17-large {
            display: block;
            grid-column-end: span 17
        }

        .ch24-grid-cols__col--span-17-large .ch24-grid-cols {
            grid-template-columns:repeat(17, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-18-large {
            display: block;
            grid-column-end: span 18
        }

        .ch24-grid-cols__col--span-18-large .ch24-grid-cols {
            grid-template-columns:repeat(18, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-19-large {
            display: block;
            grid-column-end: span 19
        }

        .ch24-grid-cols__col--span-19-large .ch24-grid-cols {
            grid-template-columns:repeat(19, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-20-large {
            display: block;
            grid-column-end: span 20
        }

        .ch24-grid-cols__col--span-20-large .ch24-grid-cols {
            grid-template-columns:repeat(20, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-21-large {
            display: block;
            grid-column-end: span 21
        }

        .ch24-grid-cols__col--span-21-large .ch24-grid-cols {
            grid-template-columns:repeat(21, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-22-large {
            display: block;
            grid-column-end: span 22
        }

        .ch24-grid-cols__col--span-22-large .ch24-grid-cols {
            grid-template-columns:repeat(22, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-23-large {
            display: block;
            grid-column-end: span 23
        }

        .ch24-grid-cols__col--span-23-large .ch24-grid-cols {
            grid-template-columns:repeat(23, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-24-large {
            display: block;
            grid-column-end: span 24
        }

        .ch24-grid-cols__col--span-24-large .ch24-grid-cols {
            grid-template-columns:repeat(24, minmax(5px, 1fr))
        }
    }
    @screen lg {
        .ch24-grid-cols__col--span-0-xlarge {
            display: none
        }

        .ch24-grid-cols__col--span-1-xlarge {
            display: block;
            grid-column-end: span 1
        }

        .ch24-grid-cols__col--span-1-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(1, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-2-xlarge {
            display: block;
            grid-column-end: span 2
        }

        .ch24-grid-cols__col--span-2-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(2, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-3-xlarge {
            display: block;
            grid-column-end: span 3
        }

        .ch24-grid-cols__col--span-3-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(3, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-4-xlarge {
            display: block;
            grid-column-end: span 4
        }

        .ch24-grid-cols__col--span-4-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(4, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-5-xlarge {
            display: block;
            grid-column-end: span 5
        }

        .ch24-grid-cols__col--span-5-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(5, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-6-xlarge {
            display: block;
            grid-column-end: span 6
        }

        .ch24-grid-cols__col--span-6-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(6, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-7-xlarge {
            display: block;
            grid-column-end: span 7
        }

        .ch24-grid-cols__col--span-7-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(7, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-8-xlarge {
            display: block;
            grid-column-end: span 8
        }

        .ch24-grid-cols__col--span-8-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(8, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-9-xlarge {
            display: block;
            grid-column-end: span 9
        }

        .ch24-grid-cols__col--span-9-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(9, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-10-xlarge {
            display: block;
            grid-column-end: span 10
        }

        .ch24-grid-cols__col--span-10-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(10, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-11-xlarge {
            display: block;
            grid-column-end: span 11
        }

        .ch24-grid-cols__col--span-11-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(11, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-12-xlarge {
            display: block;
            grid-column-end: span 12
        }

        .ch24-grid-cols__col--span-12-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(12, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-13-xlarge {
            display: block;
            grid-column-end: span 13
        }

        .ch24-grid-cols__col--span-13-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(13, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-14-xlarge {
            display: block;
            grid-column-end: span 14
        }

        .ch24-grid-cols__col--span-14-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(14, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-15-xlarge {
            display: block;
            grid-column-end: span 15
        }

        .ch24-grid-cols__col--span-15-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(15, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-16-xlarge {
            display: block;
            grid-column-end: span 16
        }

        .ch24-grid-cols__col--span-16-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(16, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-17-xlarge {
            display: block;
            grid-column-end: span 17
        }

        .ch24-grid-cols__col--span-17-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(17, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-18-xlarge {
            display: block;
            grid-column-end: span 18
        }

        .ch24-grid-cols__col--span-18-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(18, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-19-xlarge {
            display: block;
            grid-column-end: span 19
        }

        .ch24-grid-cols__col--span-19-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(19, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-20-xlarge {
            display: block;
            grid-column-end: span 20
        }

        .ch24-grid-cols__col--span-20-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(20, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-21-xlarge {
            display: block;
            grid-column-end: span 21
        }

        .ch24-grid-cols__col--span-21-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(21, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-22-xlarge {
            display: block;
            grid-column-end: span 22
        }

        .ch24-grid-cols__col--span-22-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(22, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-23-xlarge {
            display: block;
            grid-column-end: span 23
        }

        .ch24-grid-cols__col--span-23-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(23, minmax(5px, 1fr))
        }

        .ch24-grid-cols__col--span-24-xlarge {
            display: block;
            grid-column-end: span 24
        }

        .ch24-grid-cols__col--span-24-xlarge .ch24-grid-cols {
            grid-template-columns:repeat(24, minmax(5px, 1fr))
        }
    }
}
