.ch24-headline {
    color: var(--gray-800);
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-rendering: optimizeLegibility;
}

.ch24-headline.ch24-headline--headline-2 {
    font-size: 24px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 24px;
}

@screen sm {
    .ch24-headline.ch24-headline--headline-2 {
        font-size: 24px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 24px;
    }
}

@screen md {
    .ch24-headline.ch24-headline--headline-2 {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

@screen lg {
    .ch24-headline.ch24-headline--headline-2 {
        font-size: 30px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

.ch24-headline.ch24-headline--headline-3 {
    font-size: 20px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 24px;
    word-break: break-word;
    hyphens: auto;
}

@screen md {
    .ch24-headline.ch24-headline--headline-3 {
        line-height: 28px;
    }
}

@screen lg {
    .ch24-headline.ch24-headline--headline-3 {
        font-size: 20px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

.ch24-headline.ch24-headline--headline-5 {
    font-size: 18px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 24px;
}

@screen sm {
    .ch24-headline.ch24-headline--headline-5 {
        font-size: 18px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 24px;
    }
}

@screen md {
    .ch24-headline.ch24-headline--headline-5 {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}

@screen lg {
    .ch24-headline.ch24-headline--headline-5 {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: normal;
        line-height: 28px;
    }
}


.ch24-headline.ch24-headline--headline-6 {
    font-size: 18px;
    letter-spacing: normal;
    line-height: 24px;
}

@screen sm {
    .ch24-headline.ch24-headline--headline-6 {
        font-size: 20px;
        letter-spacing: normal;
        line-height: 24px;
    }
}

@screen md {
    .ch24-headline.ch24-headline--headline-6 {
        font-size: 20px;
        letter-spacing: normal;
        line-height: 24px;
    }
}

@screen lg {
    .ch24-headline.ch24-headline--headline-6 {
        font-size: 20px;
        letter-spacing: normal;
        line-height: 24px;
    }
}

.ch24-overline {
    color: var(--gray-600);
    font-size: 12px;
    line-height: 1rem;
    margin-top: 30px;
    text-transform: uppercase;

    @screen sm {
        font-size: 14px;
        margin-top: 0;
    }
}
