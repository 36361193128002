p {
  line-height: 1.2;
}

a,
a:hover,
a:active,
a:link,
a:visited {
  text-decoration: none;
}

.ch24-paragraph {
  line-height: 22px;
}

.has-ch24-paragraphs p {
  line-height: 22px;
}

.has-ch24-paragraphs p:not(:first-child) {
  @apply mt-5;
}

.ch24-paragraph--mt {
  @apply mt-5;
}

.ch24-paragraph--14-small {
  font-size: 14px;
}

.ch24-text--16 {
  font-size: 16px;
}

@screen sm {
  .ch24-paragraph--14-small {
    font-size: 16px;
  }
}
