@media (orientation: landscape) and (min-width: 1024px) and (max-width: 1279px) {
  .benefits-block-last-item {
    @apply grid grid-cols-subgrid gap-9 col-span-3;

    &>div {
      @apply col-start-2;
    }
  }
}

