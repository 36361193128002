.ch24-button[disabled] {
    cursor: not-allowed
}

.ch24-button--primary {
    color: var(--blue-600)
}

.ch24-button--primary {
    @apply transition-shadow;
}

.ch24-button--primary:focus,
.ch24-button--primary:hover {
    @apply shadow-sm;
    color: var(--blue-800)
}
