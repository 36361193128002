.ch24-filter-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 12px;

  &--no-content {
    margin: 0 18px;
  }

  .ch24-chip {

    border-radius: 25px;
    display: flex;
    align-items: center;
    background-color: var(--blue-200);
    border: 1px solid var(--blue-600);
    padding: 6px 10px;

    &__label {
      color: #575757;
    }

    &__cross {
      margin-left: 5px;

      &-icon {
        height: 10px;
        width: 10px;
      }
    }
  }
}
