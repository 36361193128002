@import "components/components";
@import "layout/layout";
@import "base/base";
@import "pages/pages";

$slick-font-path: "~slick-carousel/slick/fonts/";
$slick-loader-path: "~slick-carousel/slick/";
@import "slick-carousel/slick/slick";
@import "slick-carousel/slick/slick-theme";

@tailwind base;
@tailwind components;
@tailwind utilities;

svg {
  display: inline-block;
  fill: currentColor;
}

svg:not(:root) {
  overflow: hidden;
}

.prev-arrow {
  scale: 0.5;
  height: 40px;
  width: 40px;
}

.next-arrow {
  scale: 0.5;
  height: 40px;
  width: 40px;
}

.prev-arrow:not(.hidden) {
  & + .buttons-container:before {
    content: '';
  }
}

.buttons-container {
  &:before {
    content: none;
    background: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 3%);
  }

  &:after {
    content: '';
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 97%, rgba(255, 255, 255, 1) 100%);
  }

  &:before,
  &:after {
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10;
    pointer-events: none;
  }
}

@layer utilities {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    @apply appearance-none;
  }
}
